import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import type { OverviewTotals, OverviewTotalsParams } from './types';
import { OverviewTotalsDecoder } from './validations';

async function overviewTotals({
  organizationId,
  companyId,
  ...params
}: OverviewTotalsParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/overview/totals`,
    { params }
  );

  return decodeResponse<OverviewTotals>(response, OverviewTotalsDecoder);
}

export function useOverviewTotals(params: OverviewTotalsParams) {
  const { data, ...rest } = useQuery<OverviewTotals, ApiError>({
    enabled:
      !!params.accountsIds &&
      !!params.companyId &&
      !!params.organizationId &&
      !!params.startDate &&
      !!params.endDate,
    queryKey: overviewKeys.totals(params),
    queryFn: () => overviewTotals(params),
  });

  return { totals: data, ...rest };
}

const overviewKeys = {
  all: ['overview'] as const,
  totals: (params: OverviewTotalsParams) =>
    [...overviewKeys.all, 'totals', params] as const,
};
