import { Card, Skeleton as MuiSkeleton, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';

import { toCurrency } from 'src/libs/finbits/Money';

import styles from './TotalCard.module.scss';

type Props = {
  title: string;
  value: number;
  iconDirection?: 'up' | 'down';
};

function Skeleton() {
  return (
    <Card variant="outlined" className={styles.totalCard}>
      <MuiSkeleton variant="text" width={200} height={20} />
      <MuiSkeleton variant="rounded" width={150} height={28} />
    </Card>
  );
}

function TotalCard({ title, value, iconDirection }: Props) {
  return (
    <Card variant="outlined" className={styles.totalCard}>
      <Stack direction="row" gap={2}>
        <Typography fontWeight={600} className={styles.totalTitle}>
          {title}
        </Typography>
        {iconDirection === 'up' && (
          <ArrowCircleUpIcon className={styles.upIcon} />
        )}
        {iconDirection === 'down' && <ArrowCircleDownIcon />}
      </Stack>
      <Typography fontWeight={600} variant="text-lg">
        {toCurrency(value)}
      </Typography>
    </Card>
  );
}

TotalCard.Skeleton = Skeleton;

export default TotalCard;
