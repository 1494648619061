import { Box, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { endOfMonth, startOfMonth } from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';

import OverviewChart from 'src/features/charts/OverviewChart';

import Totals from './Totals';
import styles from './Overview.module.scss';

export default function Overview() {
  const { companyId, organizationId } = useCompanyParams();
  const { accounts } = useAccounts({ companyId, organizationId });

  const accountsIds = accounts.map((account) => account.id);
  const now = new Date();
  const startDate = startOfMonth(now);
  const endDate = endOfMonth(now);

  return (
    <Box className={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap={6}
      >
        <Typography
          variant="text-lg"
          fontWeight="bold"
          component="h6"
          marginBottom={7}
        >
          Visão Geral
        </Typography>
      </Stack>

      <Totals
        accountsIds={accountsIds}
        startDate={startDate}
        endDate={endDate}
      />

      <OverviewChart data={[]} />
    </Box>
  );
}
