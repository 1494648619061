import { Typography } from 'src/design-system/components';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { InfoCircleIcon } from 'src/mui/_icons';

import illustration from 'src/assets/zoom-chart.svg';

import styles from './ZoomTooltip.module.scss';

export default function ZoomTooltip() {
  return (
    <Tooltip
      classes={{ tooltip: styles.container, arrow: styles.arrow }}
      arrow
      title={
        <Box>
          <img
            src={illustration}
            alt="Dê zoom e navegue pelo gráfico"
            className={styles.illustration}
          />
          <Stack gap={2} className={styles.content}>
            <Typography fontWeight={600} className={styles.title}>
              Dê zoom e navegue pelo gráfico
            </Typography>
            <Typography>
              Use a rolagem do mouse para <b>aproximar e distanciar</b> de um
              ponto do gráfico.
            </Typography>
            <Typography>
              Arraste o gráfico para navegar por ele quando ampliado.
            </Typography>
          </Stack>
        </Box>
      }
    >
      <IconButton aria-label="info-circle">
        <InfoCircleIcon />
      </IconButton>
    </Tooltip>
  );
}
