import { Stack } from '@mui/material';

import { format } from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useOverviewTotals } from 'src/libs/finbits/Overview';

import TotalCard from './TotalCard';

type Props = {
  startDate: Date;
  endDate: Date;
  accountsIds: string[];
};

export default function Totals({ startDate, endDate, accountsIds }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { totals, isLoading } = useOverviewTotals({
    companyId,
    organizationId,
    accountsIds,
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  });

  if (isLoading) {
    return (
      <Stack direction="row" gap={6}>
        <TotalCard.Skeleton />
        <TotalCard.Skeleton />
        <TotalCard.Skeleton />
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={6}>
      <TotalCard
        title="Recebimentos Realizados"
        value={totals?.creditAmount!}
        iconDirection="up"
      />
      <TotalCard
        title="Pagamentos Realizados"
        value={totals?.debitAmount!}
        iconDirection="down"
      />
      <TotalCard title="Resultado do período" value={totals?.resultAmount!} />
    </Stack>
  );
}
